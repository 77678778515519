import { Alert } from '@elotech/components';
import { Loading, Wizard, formatUtils } from '@elotech/components';
import { validateCpfCnpj } from '@elotech/components/src/utils/ValidationUtils';
import { PessoasQuickView } from 'itbi-common/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  CadastroGeralService,
  CadastroRuralService,
  DeclaracaoService,
  ImobiliarioService,
  ParametroService,
  ProprietarioService,
  UploadService,
  UserService,
  withService
} from '../../service';
import Identificacao from './identificacao/Identificacao';
import Imovel from './imovel/Imovel';
import Resumo from './Resumo';

const errorMessages = {
  cadastro: 'Cadastro inválido!',
  inscricaoCadastral: 'Inscrição cadastral inválida!',
  numeroEnderecoLength: 'Número deve ter no máximo 10 caracteres',
  cadastroRural: 'Cadastro rural inválido!',
  tipoItbi: 'Tipo ITBI é obrigatório!',
  valorTransacao: 'Valor é obrigatório!',
  valorFinanciado: 'Valor é obrigatório!',
  loadDocumentos: 'Falha ao carregar os documentos!',
  compradores: 'Comprador é obrigatório!',
  compradorIncompleto: 'Comprador possui campo obrigatório não preenchido!',
  vendedores: 'Vendedor é obrigatório!',
  anuentes: 'Anuente é obrigatório!',
  percentualCompraVenda: 'Percentual compra deve ser igual ao de venda!',
  requerenteComprador: 'Requerente deve ser comprador!',
  requerenteCompradorVendedor:
    'Requerente deve ser comprador, vendedor ou anuente!',
  rascunho: 'Não foi possível salvar o rascunho!',
  documentoObrigatorio: 'O documento é obrigatório',
  documentoTamanho: 'Arquivo é maior que 50 MB',
  requerente: {
    id: 'Requerente é obrigatório',
    cpfCnpj: 'O CPF/CNPJ está inválido.',
    cpfCnpjCadastrado: 'O CPF/CNPJ já foi adicionado na lista',
    nome: 'O nome é obrigatório.',
    nomeLength: 'Máximo 150 caracteres.',
    email: 'O email é obrigatório'
  },
  cadastroInadimplente: 'Cadastro está irregular, contatar prefeitura',
  documentoProcuracaoRequerente:
    'Documento de Procuração de Requerente necessário!'
};

class DeclaracaoItbiForm extends Component {
  static propTypes = {
    imobiliarioService: PropTypes.object.isRequired,
    parametroService: PropTypes.object.isRequired,
    declaracaoService: PropTypes.object.isRequired,
    uploadService: PropTypes.object.isRequired,
    proprietarioService: PropTypes.object.isRequired,
    cadastroRuralService: PropTypes.object.isRequired,
    cadastroGeralService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    listRequerentes: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object,
    isServidor: PropTypes.bool,
    permiteItbiRural: PropTypes.bool,
    onSubmit: PropTypes.func
  };

  imobiliarioEmpty = {
    cadastro: '',
    inscricaoCadastral: '',
    zona: '',
    quadra: '',
    lote: '',
    areaTerreno: '',
    areaConstruida: '',
    cep: '',
    endereco: '',
    cidade: '',
    numero: '',
    estado: '',
    complemento: '',
    condominio: '',
    descricaoCondominio: ''
  };

  state = {
    step1: { valid: true, errorMessage: '' },
    step2: { valid: true, errorMessage: '' },
    step3: { valid: true, errorMessage: '' },
    loadingCep: false,
    loadingRascunhoEditar: false,
    pessoaQuickView: undefined,
    id: undefined,
    dadosImoveis: {
      listTipoItbi: [],
      tipoItbi: {
        label: 'Selecione...',
        anuencia: false,
        financiado: false,
        permiteTotalmenteFinanciado: false
      },
      situacaoItbi: { descricao: 'Rascunho', name: 'RASCUNHO' },
      valorTransacao: undefined,
      valorFinanciado: undefined,
      cadastroImobiliario: this.imobiliarioEmpty,
      cadastroImobiliarioLoading: false,
      cadastroRural: {},
      cadastroRuralLoading: false,
      imovelNotFoundByCadastro: false,
      imovelNotFoundByInscricaoCadastral: false,
      documentos: [],
      observacaoCidadao: '',
      observacaoServidor: '',
      tipoImovel: 'URBANO',
      requerente: {
        id: undefined,
        tipoPessoa: 'FISICA',
        cpfCnpj: '',
        nome: '',
        email: '',
        telefone: ''
      },
      error: {
        tipoItbi: false,
        loadDocumentos: false,
        valorTransacao: false,
        valorFinanciado: false,
        cadastro: false,
        inscricaoCadastral: false,
        rascunho: false,
        documentos: false,
        requerente: {},
        cadastroInadimplente: false
      }
    },
    dadosIdentificacao: {
      compradores: [],
      vendedores: [],
      anuentes: [],
      error: {
        compradorIncompleto: false,
        compradores: false,
        vendedores: false,
        anuentes: false,
        percentualCompraVenda: false,
        requerenteComprador: false
      }
    },
    vinculos: [],
    parametros: undefined,
    loadingRequerente: false,
    isCadastroInadimplente: false,
    loadingInadimplencia: false
  };

  convertProprietariosToVendedores = proprietarios => {
    if (!proprietarios) {
      return null;
    }
    return proprietarios.map(proprietario => ({
      vinculo: proprietario?.vinculo,
      nome: proprietario?.nome,
      percentual: proprietario?.percentual,
      percentualVenda: 0,
      principal: proprietario?.principal,
      cpfCnpj: proprietario?.cpfCnpj,
      tipoPessoa: proprietario?.tipoPessoa,
      cep: proprietario.endereco?.cep,
      uf: proprietario.endereco?.uf,
      numero: proprietario.endereco?.numero,
      complemento: proprietario.endereco?.complemento,
      logradouroAise: proprietario.endereco?.logradouroAise,
      tipoLogradouroAise: proprietario.endereco?.tipoLogradouroAise,
      bairroAise: proprietario.endereco?.bairroAise,
      cidadeAise: proprietario.endereco?.cidadeAise,
      ibge: proprietario.endereco?.ibge,
      logradouro: proprietario.endereco?.logradouro,
      cidade: proprietario.endereco?.cidade,
      bairro: proprietario.endereco?.bairro
    }));
  };

  componentDidMount = () => {
    const {
      match: { params },
      location,
      parametroService,
      proprietarioService,
      isServidor,
      permiteItbiRural
    } = this.props;

    if (params.id) {
      this.loadRascunho(params.id);
    }

    if (isServidor && location?.search) {
      const searchParams = Object.fromEntries(
        new URLSearchParams(location.search)
      );
      const tipoImovelRural = searchParams.tipoImovel === 'RURAL';
      const cadastro = searchParams.cadastro;
      this.setState(
        prevState => {
          return {
            dadosImoveis: {
              ...prevState.dadosImoveis,
              requerente: {
                nome: decodeURI(searchParams.nome ?? ''),
                cpfCnpj: searchParams.cpfCnpj,
                tipoPessoa:
                  searchParams.cpfCnpj?.length > 11 ? 'JURIDICA' : 'FISICA'
              },
              tipoImovel: permiteItbiRural ? searchParams.tipoImovel : 'URBANO',
              cadastroImobiliario: !tipoImovelRural
                ? {
                    cadastro: cadastro
                  }
                : prevState.dadosImoveis.cadastroImobiliario,
              cadastroRural: tipoImovelRural
                ? { cadastro: cadastro }
                : prevState.cadastroRural
            }
          };
        },
        () =>
          tipoImovelRural
            ? this.searchCadastroRural(cadastro)
            : this.onSearchCadastroImobiliario(cadastro)
      );
      this.props.history.replace('/declaracoes-itbi/novo');
    }

    parametroService
      .loadTiposItbi(isServidor)
      .then(response => {
        if (response.data) {
          this.setState(
            this.newStateDadosImoveis({
              listTipoItbi: this.mountlistTipoItbi(response.data)
            })
          );
        }
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar dados dos imóveis.'
          },
          error
        );
      });

    parametroService
      .loadAllParametros()
      .then(response => this.setState({ parametros: response.data }))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar os parâmetros.'
          },
          error
        );
      });
    proprietarioService
      .loadVinculos()
      .then(response => {
        if (response.data) {
          this.setState({ vinculos: response.data });
        }
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar vínculos.'
          },
          error
        );
      });
  };

  getDadosDeclaracao = () => {
    const { id, dadosImoveis, dadosIdentificacao } = this.state;
    const {
      tipoItbi,
      cadastroImobiliario,
      cadastroRural,
      tipoImovel
    } = dadosImoveis;

    return {
      id,
      tipoItbi: { id: tipoItbi.value },
      cadastroImobiliario:
        tipoImovel === 'URBANO'
          ? {
              id: cadastroImobiliario.id
            }
          : undefined,
      valorTransacao: dadosImoveis.valorTransacao,
      valorFinanciado: dadosImoveis.valorFinanciado || 0,
      observacaoCidadao: dadosImoveis.observacaoCidadao,
      observacaoServidor: dadosImoveis.observacaoServidor,
      compradores: dadosIdentificacao.compradores,
      vendedores: dadosIdentificacao.vendedores,
      anuentes: dadosIdentificacao.anuentes,
      requerente: {
        id: dadosImoveis.requerente.id
      },
      requerenteTipoPessoa: dadosImoveis.requerente.tipoPessoa,
      requerenteCpfCnpj: dadosImoveis.requerente.cpfCnpj,
      requerenteNome: dadosImoveis.requerente.nome,
      requerenteEmail: dadosImoveis.requerente.email,
      requerenteTelefone: dadosImoveis.requerente.telefone,
      origemServidor: this.props.isServidor,
      tipoImovel: dadosImoveis.tipoImovel,
      cadastroRural:
        tipoImovel === 'RURAL'
          ? {
              id: cadastroRural.id
            }
          : undefined
    };
  };

  finalizar = () => {
    if (
      !this.onValidateDadosImoveis(this.state).valid ||
      !this.onValidateDadosIdentificacao(this.state).valid ||
      (this.state.isCadastroInadimplente &&
        this.state.parametros.bloqueiaItbiCadInadimplente)
    ) {
      return;
    }
    const {
      declaracaoService,
      showNotification,
      history,
      onSubmit
    } = this.props;

    const declaracao = this.getDadosDeclaracao();

    this.setStateSubmitting('step3', true);

    declaracaoService
      .salvarDeclaracao(declaracao)
      .then(response => {
        this.setStateSubmitting('step3', false);
        showNotification({
          level: 'success',
          message: 'Declaração gerada com sucesso'
        });

        if (onSubmit) {
          return onSubmit(response.data);
        }
        history.replace(`/declaracoes-itbi/${response.data.id}/resumo`, {
          novo: true
        });
      })
      .catch(error => {
        this.setStateSubmitting('step3', false);
        Alert.error({ title: 'Não foi possível salvar o rascunho' }, error);
      });
  };

  salvarRascunho = async () => {
    const { isServidor } = this.props;
    const declaracao = this.getDadosDeclaracao();

    const save = declaracao.id
      ? this.props.declaracaoService.updateRascunho
      : this.props.declaracaoService.salvarRascunho;

    const response = await save(declaracao);

    if (response.data) {
      this.setState(
        {
          id: response.data.id
        },
        () => {
          if (isServidor) {
            this.setState(prevState => {
              return {
                dadosIdentificacao: {
                  ...prevState.dadosIdentificacao,
                  vendedores: response.data.vendedores
                }
              };
            });
          }
        }
      );
      await this.uploadDocumentS3(response.data.id);
      await this.salvarDocumentos();
    }
  };

  salvarDocumentos = async () => {
    const { id, dadosImoveis } = this.state;
    const newDocumentos = dadosImoveis.documentos.map(documento => ({
      id: documento.idDeclaracao,
      documentoItbi: { id: documento.id },
      valor:
        documento.value && documento.value.length > 0
          ? documento.value[0].name
          : '',
      campos: documento.campos.map(campo => ({
        id: campo.idCampoDeclaracao,
        campoDocumento: { id: campo.id },
        valor: campo.value ? campo.value : ''
      }))
    }));

    await this.props.declaracaoService.salvarDocumentos(id, newDocumentos);
  };

  uploadDocumentS3 = async declaracao => {
    const { dadosImoveis } = this.state;

    return new Promise(async (resolve, reject) => {
      Promise.all(
        dadosImoveis.documentos.map(async documento => {
          if (
            documento.value &&
            documento.value.length > 0 &&
            !documento.uploaded
          ) {
            const responseUrl = await this.props.uploadService.getUrlUploadS3Declaracao(
              {
                declaracao,
                documento: documento.id,
                arquivo: documento.value[0].name
              }
            );

            await this.props.uploadService.uploadFileS3(
              responseUrl.data.url,
              documento.value[0]
            );
          }
          return { ...documento, uploaded: true };
        })
      )
        .then(newDocumentos => {
          this.setState(
            this.newStateDadosImoveis({
              documentos: newDocumentos
            }),
            () => {
              resolve();
            }
          );
        })
        .catch(error => {
          this.props.showNotification({
            level: 'error',
            message: 'Não foi possível salvar o documento!'
          });
          reject(error);
        });
    });
  };

  getErrorMessage = errors => {
    const arrayErrors = Object.keys(errors)
      .map(field => {
        return {
          field,
          message: errorMessages[field],
          error: errors[field]
        };
      })
      .filter(e => e.error);

    if (arrayErrors.length > 0) {
      return arrayErrors.shift().message;
    }

    return '';
  };

  onBeforeChange = async (oldStepData, newStepData) => {
    const stepDadosImoveis = '1';
    const stepIdentificacao = '2';
    let formValid = {};

    if (newStepData.index < oldStepData.index) {
      return { oldStepData, newStepData };
    }

    if (oldStepData.stepId === stepDadosImoveis) {
      formValid = this.onValidateDadosImoveis(this.state);

      if (formValid.valid) {
        try {
          this.setStateSubmitting('step1', true);
          await this.salvarRascunho();

          this.setStateSubmitting('step1', false);
        } catch (error) {
          formValid.valid = false;
          this.setStateSubmitting('step1', false);
        }
      }
    } else if (oldStepData.stepId === stepIdentificacao) {
      formValid = this.onValidateDadosIdentificacao(this.state);
    }

    const { ...oldStep } = oldStepData;
    oldStep.valid = formValid.valid;
    oldStep.errorMessage = formValid.message;

    return { oldStepData: oldStep, newStepData };
  };

  findNomeRequerente = (requerente = {}) => {
    const { listRequerentes = [] } = this.props;

    const found = listRequerentes.find(item => item.id === requerente.id);

    return (found && found.nome) || requerente.id;
  };

  loadRascunho = id => {
    this.setState({ loadingRascunhoEditar: true });
    this.props.declaracaoService
      .loadDeclaracoesById(id)
      .then(response => {
        this.setState({ loadingRascunhoEditar: false });
        const { data } = response;
        if (data) {
          this.setState({
            id: data.id,
            dadosImoveis: {
              ...this.state.dadosImoveis,
              requerente: {
                ...data.requerente,
                cpfCnpj: data.requerenteCpfCnpj,
                telefone: data.requerenteTelefone,
                email: data.requerenteEmail,
                nome:
                  data.requerenteNome ||
                  this.findNomeRequerente(data.requerente),
                tipoPessoa: data.requerenteTipoPessoa
              },
              tipoImovel: data.tipoImovel,
              cadastroImobiliario: data.cadastroImobiliario ?? {},
              cadastroRural: data.cadastroRural ?? {},
              tipoItbi: {
                ...data.tipoItbi,
                value: data.tipoItbi.id,
                label: data.tipoItbi.descricao,
                observacao: data.tipoItbi.observacao
              },
              valorTransacao: data.valorTransacao,
              valorFinanciado: data.valorFinanciado,
              observacaoCidadao: data.observacaoCidadao,
              documentos: data.documentos.map(documento => ({
                id: documento.documentoItbi.id,
                nome: documento.documentoItbi.nome,
                obrigatorio: documento.documentoItbi.obrigatorio,
                value: documento.valor ? [{ name: documento.valor }] : '',
                uploaded: true,
                campos: documento.campos.map(campo => ({
                  id: campo.campoDocumento.id,
                  label: campo.campoDocumento.label,
                  tipoCampo: campo.campoDocumento.tipoCampo,
                  value: campo.valor
                }))
              }))
            },
            dadosIdentificacao: {
              ...this.state.dadosIdentificacao,
              vendedores: data.vendedores
                ? data.vendedores
                : this.state.dadosIdentificacao.vendedores,
              compradores: data.compradores
                ? data.compradores
                : this.state.dadosIdentificacao.compradores,
              anuentes: data.anuentes
                ? data.anuentes
                : this.state.dadosIdentificacao.anuentes
            }
          });

          if (data.tipoItbi && data.documentos.length === 0) {
            this.loadDocumentosPorTipo();
          }
        }
      })
      .catch(() => {
        alert(
          'Não foi possivel carregar os dados previamente preenchidos no rascunho!'
        );
        this.setState({ loadingRascunhoEditar: false });
      });
  };

  //#region dadosImoveis
  newStateDadosImoveis = dados => state => {
    return {
      dadosImoveis: {
        ...state.dadosImoveis,
        ...dados
      }
    };
  };

  setStateSubmitting = (step, submitting) => {
    this.setState(state => ({
      [step]: {
        ...state[step],
        submitting
      }
    }));
  };

  mountlistTipoItbi = listTipoItbi => {
    return listTipoItbi.map(tipoItbi => ({
      value: tipoItbi.id,
      label: tipoItbi.descricao,
      observacao: tipoItbi.observacao,
      anuencia: tipoItbi.anuencia,
      financiado: tipoItbi.financiado,
      permiteTotalmenteFinanciado: tipoItbi.permiteTotalmenteFinanciado
    }));
  };

  handleChangeField = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { error } = state.dadosImoveis;
      const newError = error[name] ? !value : !!error[name];
      return this.newStateDadosImoveis({
        [name]: value,
        error: { ...error, [name]: newError }
      })(state);
    });
  };

  handleChangeFieldCurrency = ({ floatValue }, event) => {
    const { name } = event.target;
    this.setState(state => {
      const { error } = state.dadosImoveis;
      const newError = error[name] ? !floatValue : !!error[name];
      return this.newStateDadosImoveis({
        [name]: floatValue,
        error: { ...error, [name]: newError }
      })(state);
    });
  };

  handleChangeFieldDocument = event => {
    const { id, value, checked, type } = event.target;
    const idDocumento = event.target.getAttribute('data-id');
    let newDocumentos = [...this.state.dadosImoveis.documentos];
    const newValue = type === 'checkbox' ? checked : value;
    const documento = newDocumentos.find(f => f.id === idDocumento);
    documento.campos.map(field => {
      if (`${field.id}` === `${id}`) {
        field.value = newValue;
        field.error = false;
        field.errorMessage = '';
      }
      return field;
    });
    this.setState(
      this.newStateDadosImoveis({
        documentos: newDocumentos
      })
    );
  };

  tamanhoValido = files => {
    return files && files[0] && files[0].size <= 50000000;
  };

  handleChangeUploadFile = event => {
    const { files } = event.target;
    const idDocumento = event.target.getAttribute('data-id');
    let newDocumentos = [...this.state.dadosImoveis.documentos];
    const documento = newDocumentos.find(f => f.id === idDocumento);

    if (this.tamanhoValido(files)) {
      documento.value = files;
      documento.error = false;
      documento.errorMessage = '';
      documento.uploaded = false;
    } else {
      documento.value = files;
      documento.error = true;
      documento.errorMessage = errorMessages.documentoTamanho;
      documento.uploaded = false;
    }
    this.setState(
      this.newStateDadosImoveis({
        documentos: newDocumentos
      })
    );
  };

  handleChangeCadastroImobiliario = event => {
    const { isServidor } = this.props;
    const { name, value } = event.target;
    this.setState(state => {
      const { error } = state.dadosImoveis;
      const newError = error[name] ? !value : !!error[name];
      const imobiliario = isServidor
        ? state.dadosImoveis.cadastroImobiliario
        : this.imobiliarioEmpty;

      return this.newStateDadosImoveis({
        imovelNotFoundByCadastro: false,
        imovelNotFoundByInscricaoCadastral: false,
        cadastroImobiliario: { ...imobiliario, [name]: value },
        error: { ...error, [name]: newError }
      })(state);
    });
  };

  handleChangeCadastroRural = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { error } = state.dadosImoveis;
      const newError = error[name] ? !value : !!error[name];

      const oldRural = state.dadosImoveis.cadastroRural ?? {};

      return this.newStateDadosImoveis({
        ruralNotFound: false,
        cadastroRural: { ...oldRural, [name]: value },
        error: { ...error, [name]: newError }
      })(state);
    });
  };

  handleClearCadastroImobiliario = () => {
    this.setState(
      this.newStateDadosImoveis({
        imovelNotFoundByCadastro: false,
        imovelNotFoundByInscricaoCadastral: false,
        cadastroImobiliario: { ...this.imobiliarioEmpty }
      })
    );
  };

  resetAnuentes = () => {
    this.setState(state => ({
      dadosIdentificacao: {
        ...state.dadosIdentificacao,
        anuentes: []
      }
    }));
  };

  handleChangeSelectTipoItbi = event => {
    const { value } = event.target;
    const { dadosImoveis } = this.state;
    if (dadosImoveis.tipoItbi.value !== value) {
      const tipoItbi = dadosImoveis.listTipoItbi.find(
        tipo => tipo.value === value
      );

      this.setState(
        state => {
          const { error } = state.dadosImoveis;
          return this.newStateDadosImoveis({
            tipoItbi: tipoItbi || {},
            valorFinanciado:
              tipoItbi && (tipoItbi.financiado || tipoItbi.anuencia)
                ? state.dadosImoveis.valorFinanciado
                : undefined,
            error: {
              ...error,
              tipoItbi: false,
              loadDocumentos: false
            }
          })(state);
        },
        () => this.loadDocumentosPorTipo()
      );

      this.resetAnuentes();
    }
  };

  handleChangeSelectRequerente = event => {
    const { value } = event.target;
    const { listRequerentes = [] } = this.props;

    const requerente = listRequerentes.find(item => item.id === value);

    this.setState(state => {
      const { error } = state.dadosImoveis;

      return this.newStateDadosImoveis({
        requerente: requerente || {},
        error: {
          ...error,
          requerente: {
            id: false
          }
        }
      })(state);
    });
  };

  handleKeyDownCadastroImobiliario = event => {
    const enterKey = 13;
    if ([enterKey].includes(event.keyCode)) {
      this.onSearchCadastroImobiliario(event.target.value);
    }
  };

  handleBlurCadastroImobiliario = event => {
    this.onSearchCadastroImobiliario(event.target.value);
  };

  loadDocumentosPorTipo = () => {
    const { dadosImoveis } = this.state;

    if (!dadosImoveis.tipoItbi.value) {
      this.setState(this.newStateDadosImoveis({ documentos: [] }));
    } else {
      this.props.parametroService
        .loadDocumentoByTipoItbi(dadosImoveis.tipoItbi.value)
        .then(response => {
          if (response.data) {
            this.setState(
              this.newStateDadosImoveis({ documentos: response.data })
            );
          }
        })
        .catch(() =>
          this.setState(
            this.newStateDadosImoveis({ error: { loadDocumentos: true } })
          )
        );
    }
  };

  shouldFindByInscricaoCadastral = search => {
    const { parametros, dadosImoveis } = this.state;
    const { inscricaoCadastral } = dadosImoveis.cadastroImobiliario;

    return (
      parametros?.buscaImobiliarioPorInscricao && inscricaoCadastral === search
    );
  };

  findByInscricaoCadastral = search => {
    this.setState(
      this.newStateDadosImoveis({ cadastroImobiliarioLoading: true })
    );

    const { isServidor, imobiliarioService } = this.props;

    return imobiliarioService
      .findByInscricaoCadastral(search)
      .then(response => {
        if (response.data) {
          this.setState(
            prevState => {
              return {
                dadosImoveis: {
                  ...prevState.dadosImoveis,
                  cadastroImobiliario: { ...response.data },
                  imovelNotFoundByInscricaoCadastral: false,
                  error: {
                    ...prevState.dadosImoveis.error,
                    inscricaoCadastral: false
                  }
                }
              };
            },
            () => {
              if (isServidor) {
                this.setState(prevState => {
                  return {
                    dadosIdentificacao: {
                      ...prevState.dadosIdentificacao,
                      vendedores: this.convertProprietariosToVendedores(
                        response?.data?.proprietarios
                      )
                    }
                  };
                });
              }
            }
          );
        }
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.setState(
            this.newStateDadosImoveis({
              imovelNotFoundByInscricaoCadastral: true
            })
          );
        } else {
          this.setState(prevState => {
            return {
              dadosImoveis: {
                ...prevState.dadosImoveis,
                error: {
                  ...prevState.dadosImoveis.error,
                  inscricaoCadastral: true
                }
              }
            };
          });
        }
      })
      .finally(() =>
        this.setState(
          this.newStateDadosImoveis({
            cadastroImobiliarioLoading: false
          })
        )
      );
  };

  findCadastroImobiliario = search => {
    this.setState(
      this.newStateDadosImoveis({ cadastroImobiliarioLoading: true })
    );

    const { isServidor, imobiliarioService } = this.props;

    return imobiliarioService
      .findCadastroImobiliario(search)
      .then(response => {
        if (response.data) {
          this.setState(
            prevState => {
              return {
                dadosImoveis: {
                  ...prevState.dadosImoveis,
                  cadastroImobiliario: { ...response.data },
                  imovelNotFoundByCadastro: false,
                  error: {
                    ...prevState.dadosImoveis.error,
                    cadastro: false
                  }
                }
              };
            },
            () => {
              if (isServidor) {
                this.setState(prevState => {
                  return {
                    dadosIdentificacao: {
                      ...prevState.dadosIdentificacao,
                      vendedores: this.convertProprietariosToVendedores(
                        response?.data?.proprietarios
                      )
                    }
                  };
                });
              }
            }
          );
        }
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.setState(
            this.newStateDadosImoveis({ imovelNotFoundByCadastro: true })
          );
        } else {
          this.setState(prevState => {
            return {
              dadosImoveis: {
                ...prevState.dadosImoveis,
                error: {
                  ...prevState.dadosImoveis.error,
                  cadastro: true
                }
              }
            };
          });
        }
      })
      .finally(() =>
        this.setState(
          this.newStateDadosImoveis({
            cadastroImobiliarioLoading: false
          })
        )
      );
  };

  onSearchCadastroImobiliario = search => {
    if (!search) return;

    const promise = this.shouldFindByInscricaoCadastral(search)
      ? this.findByInscricaoCadastral(search)
      : this.findCadastroImobiliario(search);

    return promise.then(res => res && this.verificarInadimplencia(res.data));
  };

  searchCadastroRural = cadastro => {
    if (!cadastro) {
      return;
    }
    this.setState(this.newStateDadosImoveis({ cadastroRuralLoading: true }));

    const { isServidor, cadastroRuralService } = this.props;

    return cadastroRuralService
      .buscarCadastro(cadastro)
      .then(response => {
        if (response.data) {
          this.setState(
            prevState => {
              return {
                dadosImoveis: {
                  ...prevState.dadosImoveis,
                  cadastroRural: { ...response.data },
                  ruralNotFound: false,
                  error: {
                    ...prevState.dadosImoveis.error,
                    cadastroRural: false
                  }
                }
              };
            },
            () => {
              if (isServidor) {
                this.setState(prevState => {
                  return {
                    dadosIdentificacao: {
                      ...prevState.dadosIdentificacao,
                      vendedores: this.convertProprietariosToVendedores(
                        response?.data?.proprietarios
                      )
                    }
                  };
                });
              }
            }
          );
        }
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.setState(
            this.newStateDadosImoveis({
              ruralNotFound: true,
              cadastroRural: {}
            })
          );
        } else {
          this.setState(prevState => {
            return {
              dadosImoveis: {
                ...prevState.dadosImoveis,
                cadastroRural: {},
                error: {
                  ...prevState.dadosImoveis.error,
                  cadastroRural: true
                }
              }
            };
          });
        }
      })
      .finally(() =>
        this.setState(
          this.newStateDadosImoveis({ cadastroRuralLoading: false })
        )
      );
  };

  onValidateDadosImoveis = ({ dadosImoveis }) => {
    const { isServidor } = this.props;
    const { parametros } = this.state;
    const { tipoItbi, tipoImovel } = dadosImoveis;
    const { error } = dadosImoveis;

    const isInvalidValorTransacao =
      !dadosImoveis.valorTransacao || dadosImoveis.valorTransacao <= 0;

    let message = '';

    error.tipoItbi = !tipoItbi.value;

    error.valorTransacao =
      isServidor &&
      (tipoItbi.financiado || tipoItbi.anuencia) &&
      tipoItbi.permiteTotalmenteFinanciado
        ? false
        : isInvalidValorTransacao;

    error.valorFinanciado =
      (tipoItbi.financiado || tipoItbi.anuencia) &&
      (!dadosImoveis.valorFinanciado || dadosImoveis.valorFinanciado <= 0);

    error.cadastro =
      tipoImovel === 'URBANO' && dadosImoveis.cadastroImobiliario.cadastro <= 0;

    error.inscricaoCadastral =
      tipoImovel === 'URBANO' &&
      parametros?.buscaImobiliarioPorInscricao &&
      dadosImoveis.cadastroImobiliario.inscricaoCadastral <= 0;

    error.cadastroRural =
      tipoImovel === 'RURAL' && (dadosImoveis.cadastroRural.cadastro ?? 0) <= 0;

    error.numeroEndereco = dadosImoveis.cadastroImobiliario.numero?.length > 10;

    error.documentos = this.validateDocumentos(dadosImoveis);

    error.requerente = { id: !isServidor && !dadosImoveis.requerente.id };

    error.cadastroInadimplente =
      this.state.isCadastroInadimplente &&
      this.state.parametros.bloqueiaItbiCadInadimplente;

    if (isServidor) {
      error.requerente.tipoPessoa = !dadosImoveis.requerente.tipoPessoa;
      error.requerente.cpfCnpj = !dadosImoveis.requerente.cpfCnpj;
      error.requerente.nome = !dadosImoveis.requerente.nome;
      error.requerente.nomeLength = dadosImoveis.requerente.nome.length > 150;
    }

    this.setState(this.newStateDadosImoveis({ error }));

    const valid =
      !Object.values(error).includes(true) &&
      !Object.values(error.requerente).includes(true);

    if (!valid) {
      message = 'Campos Obrigatórios!';
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios!'
      });
    }

    this.setState({
      step1: {
        ...this.state.step1,
        valid,
        errorMessage: message
      }
    });

    return { valid, message, error };
  };

  validaDocumento = documento => {
    const { obrigatorio, value, uploaded } = documento;

    if (obrigatorio && (!value || value.length === 0)) {
      return {
        error: true,
        errorMessage: errorMessages.documentoObrigatorio
      };
    }

    if (!uploaded && value && !this.tamanhoValido(value)) {
      return {
        error: true,
        errorMessage: errorMessages.documentoTamanho
      };
    }

    return {
      error: false,
      errorMessage: ''
    };
  };

  validateDocumentos = ({ documentos }) => {
    if (documentos.length === 0) {
      return false;
    }
    const newDocumentos = documentos.map(documento => {
      const erroDocumento = this.validaDocumento(documento);

      return {
        ...documento,
        error: erroDocumento.error,
        errorMessage: erroDocumento.errorMessage,
        campos: documento.campos.map(field => {
          const campoHasError = documento.obrigatorio && !field.value;
          return {
            ...field,
            error: campoHasError,
            errorMessage: campoHasError ? `${field.label} é obrigatório!` : ''
          };
        })
      };
    });
    this.setState(this.newStateDadosImoveis({ documentos: newDocumentos }));

    return (
      newDocumentos.some(doc => doc.error) ||
      newDocumentos
        .map(({ campos }) => campos)
        .reduce((accum, valor) => accum.concat(valor), [])
        .some(campo => campo.error)
    );
  };

  //#endregion dadosImoveis

  faltaDocumentoProcuracaoDeRequerente(dadosImoveis, dadosIdentificacao) {
    const isRequerenteEntreOsCompradores = this.requerenteInList(
      dadosImoveis.requerente,
      dadosIdentificacao.compradores
    );
    const documentosQueExigemProcuracao = dadosImoveis.documentos.filter(
      documento => documento.procuracaoRequerente
    );
    const documentosComProcuracaoAssinada = documentosQueExigemProcuracao.filter(
      documento => documento.value
    );
    return (
      documentosQueExigemProcuracao.length !==
        documentosComProcuracaoAssinada.length &&
      !isRequerenteEntreOsCompradores
    );
  }

  //#region identificacao
  onValidateDadosIdentificacao = ({ dadosIdentificacao, dadosImoveis }) => {
    const { error } = dadosIdentificacao;
    const { parametros } = this.state;
    let message = '';

    error.documentoProcuracaoRequerente = this.faltaDocumentoProcuracaoDeRequerente(
      dadosImoveis,
      dadosIdentificacao
    );

    error.compradores = dadosIdentificacao.compradores.length === 0;

    error.compradorIncompleto = parametros?.validaRgComprador
      ? this.possuiCompradorSemRg(dadosIdentificacao.compradores)
      : false;

    error.vendedores = dadosIdentificacao.vendedores.length === 0;

    if (dadosImoveis.tipoItbi.anuencia) {
      error.anuentes = dadosIdentificacao.anuentes.length === 0;
    }

    error.percentualCompraVenda = this.validatePercentualCompraVenda(
      dadosIdentificacao
    );

    if (formatUtils.isCpf(dadosImoveis.requerente.cpfCnpj)) {
      error.requerenteCompradorVendedor = false;

      error.requerenteComprador = this.validateRequerenteComprador(
        dadosImoveis,
        dadosIdentificacao
      );
    } else {
      error.requerenteComprador = false;

      error.requerenteCompradorVendedor = this.validateRequerenteParteProcesso(
        dadosImoveis,
        dadosIdentificacao
      );
    }

    this.setState(this.newStateDadosIdentificacao({ error }));
    const valid = !Object.values(error).includes(true);

    if (!valid) {
      message = 'Formulário inválido!';
      this.props.showNotification({
        level: 'error',
        message:
          this.getErrorMessage(this.state.dadosIdentificacao.error) !== ''
            ? this.getErrorMessage(this.state.dadosIdentificacao.error)
            : message
      });
    }

    this.setState({
      step2: {
        ...this.state.step2,
        valid,
        errorMessage: message
      }
    });

    return { valid, message };
  };

  possuiCompradorSemRg = compradores => {
    return compradores.some(
      comprador =>
        comprador.tipoPessoa === 'FISICA' &&
        (!comprador.rg || !comprador.rgOrgaoEmissor || !comprador.rgUf)
    );
  };

  validatePercentualCompraVenda = ({ compradores, vendedores }) => {
    const percentualCompra = this.getPercentual(compradores);
    const percentualVendedores = this.getPercentualVenda(vendedores);

    return (
      formatUtils.round(percentualCompra, 2) !==
      formatUtils.round(percentualVendedores, 2)
    );
  };

  validateRequerenteComprador = ({ requerente }, { compradores }) => {
    if (this.props.isServidor || this.state.parametros?.naoValidaRequerente) {
      return false;
    }

    return (
      !requerente.procurador && !this.requerenteInList(requerente, compradores)
    );
  };

  validateRequerenteParteProcesso = (
    { requerente },
    { compradores, vendedores, anuentes }
  ) => {
    if (this.props.isServidor) {
      return false;
    }

    return (
      !requerente.procurador &&
      !this.requerenteInList(requerente, compradores) &&
      !this.requerenteInList(requerente, vendedores) &&
      !this.requerenteInList(requerente, anuentes)
    );
  };

  requerenteInList = (requerente, list) =>
    list && list.some(m => m.cpfCnpj === requerente.cpfCnpj);

  getPercentual = array => {
    return array
      .map(m => +m.percentual)
      .reduce((accum, valor) => accum + valor, 0);
  };

  getPercentualVenda = array => {
    return array
      .map(m => +m.percentualVenda)
      .reduce((accum, valor) => accum + valor, 0);
  };

  newStateDadosIdentificacao = dados => state => {
    return {
      dadosIdentificacao: {
        ...state.dadosIdentificacao,
        ...dados
      }
    };
  };

  adicionarListaIdentificadores = (
    fieldName,
    pluralFieldName,
    addFn
  ) => async value => {
    const { [pluralFieldName]: lista } = this.state.dadosIdentificacao;
    const error = {
      ...this.state.dadosIdentificacao.error,
      [pluralFieldName]: false
    };
    try {
      await addFn(this.state.id, value).then(response => {
        const newValue = { ...value, id: response.data.id };
        const newLista = [...lista, newValue];
        this.setState(
          this.newStateDadosIdentificacao({
            [pluralFieldName]: newLista,
            error
          })
        );
      });
    } catch (error) {
      Alert.error({ title: `Falha ao adicionar ${fieldName}!` }, error);
    }
  };

  adicionarAnuente = this.adicionarListaIdentificadores(
    'anuente',
    'anuentes',
    this.props.declaracaoService.updateAnuentes
  );
  adicionarComprador = this.adicionarListaIdentificadores(
    'comprador',
    'compradores',
    this.props.declaracaoService.updateCompradores
  );
  adicionarVendedor = this.adicionarListaIdentificadores(
    'vendedor',
    'vendedores',
    this.props.declaracaoService.updateVendedores
  );

  editarListaIdentificadores = (fieldName, pluralFieldName, updateFn) => async (
    value,
    posicao
  ) => {
    const { [pluralFieldName]: lista } = this.state.dadosIdentificacao;
    const newLista = lista
      .slice(0, posicao)
      .concat([value])
      .concat(lista.slice(posicao + 1));
    try {
      await updateFn(this.state.id, value).then(() =>
        this.setState(
          this.newStateDadosIdentificacao({ [pluralFieldName]: newLista })
        )
      );
    } catch (error) {
      Alert.error(
        {
          title: `Falha ao atualizar ${fieldName}!`
        },
        error
      );
    }
  };

  editarAnuente = this.editarListaIdentificadores(
    'anuente',
    'anuentes',
    this.props.declaracaoService.updateAnuentes
  );
  editarComprador = this.editarListaIdentificadores(
    'comprador',
    'compradores',
    this.props.declaracaoService.updateCompradores
  );
  editarVendedor = this.editarListaIdentificadores(
    'vendedor',
    'vendedores',
    this.props.declaracaoService.updateVendedores
  );

  deletarListaIdentificadores = (
    fieldName,
    pluralFieldName,
    deleteFn
  ) => async posicao => {
    const { [pluralFieldName]: lista } = this.state.dadosIdentificacao;
    const elemento = lista[posicao];
    const newLista = lista.slice(0, posicao).concat(lista.slice(posicao + 1));
    try {
      this.setState({ loadingRascunhoEditar: true });
      await deleteFn(this.state.id, elemento.id).then(() => {
        this.setState(
          this.newStateDadosIdentificacao({ [pluralFieldName]: newLista })
        );
        this.setState({ loadingRascunhoEditar: false });
      });
    } catch (error) {
      this.setState({ loadingRascunhoEditar: false });
      this.props.showNotification({
        level: 'error',
        message: `Falha ao remover ${fieldName}!`
      });
    }
  };

  deletarComprador = this.deletarListaIdentificadores(
    'comprador',
    'compradores',
    this.props.declaracaoService.deleteCompradores
  );
  deletarVendedor = this.deletarListaIdentificadores(
    'vendedor',
    'vendedores',
    this.props.declaracaoService.deleteVendedores
  );
  deletarAnuente = this.deletarListaIdentificadores(
    'anuente',
    'anuentes',
    this.props.declaracaoService.deleteAnuentes
  );

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () => {
    this.setState({
      pessoaQuickView: undefined
    });
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;
    const newValue = ['cep', 'telefone'].includes(name)
      ? value.replace(/\D/g, '')
      : value;

    this.setState(prevState => ({
      dadosImoveis: {
        ...prevState.dadosImoveis,
        requerente: {
          ...prevState.dadosImoveis.requerente,
          [name]: newValue
        },
        error: {
          ...prevState.dadosImoveis.error,
          requerente: {
            ...prevState.dadosImoveis.error.requerente,
            [name]: false,
            [`${name}Length`]: false
          }
        }
      }
    }));
  };

  onChangeInputCpfCnpj = event => {
    const { value } = event.target;
    const { showNotification, userService } = this.props;

    const cpfCnpj = value.replace(/\D/g, '');

    this.setState(prevState => ({
      dadosImoveis: {
        ...prevState.dadosImoveis,
        requerente: {
          ...prevState.dadosImoveis.requerente,
          cpfCnpj
        }
      }
    }));

    if (validateCpfCnpj(cpfCnpj)) {
      this.setState({ loadingRequerente: true });
      userService
        .getUsuarioTributosByCpf(cpfCnpj)
        .then(response => {
          if (response.data) {
            const { id, nome, telefone, email, tipoPessoa } = response.data;

            this.setState(prevState => ({
              dadosImoveis: {
                ...prevState.dadosImoveis,
                requerente: {
                  ...prevState.dadosImoveis.requerente,
                  id,
                  nome,
                  telefone,
                  email,
                  tipoPessoa
                }
              }
            }));
          } else {
            this.setState(prevState => ({
              dadosImoveis: {
                ...prevState.dadosImoveis,
                requerente: {
                  ...prevState.dadosImoveis.requerente,
                  id: undefined
                }
              }
            }));
          }
        })
        .catch(() => {
          showNotification({
            level: 'error',
            message: 'Erro ao carregar usuário por CPF/CNPJ'
          });
        })
        .finally(() => {
          this.setState(prevState => ({
            dadosImoveis: {
              ...prevState.dadosImoveis,
              error: {
                ...prevState.dadosImoveis.error,
                requerente: {
                  ...prevState.dadosImoveis.error.requerente,
                  cpfCnpj: false
                }
              }
            },
            loadingRequerente: false
          }));
        });
    } else {
      this.setState(prevState => ({
        dadosImoveis: {
          ...prevState.dadosImoveis,
          error: {
            ...prevState.dadosImoveis.error,
            requerente: {
              ...prevState.dadosImoveis.error.requerente,
              cpfCnpj: true
            }
          }
        }
      }));
    }
  };

  verificarInadimplencia = cadastroParaItbi => {
    const { tipoCadastro, cadastro } = cadastroParaItbi;
    const { bloqueiaItbiCadInadimplente } = this.state.parametros;
    const { cadastroGeralService } = this.props;
    this.setState({ loadingInadimplencia: true });
    cadastroGeralService
      .isInadimplente(tipoCadastro, cadastro)
      .then(response => {
        this.setState({
          loadingInadimplencia: false,
          isCadastroInadimplente: response.data
        });

        response.data &&
          bloqueiaItbiCadInadimplente &&
          Alert.warning({
            title: 'Atenção',
            text:
              'Não é possivel realizar o pedido para este cadastro, pois ele se encontra irregular. Entre em contato como municipio para regularização.'
          });
      })
      .catch(err => {
        this.setState({ loadingInadimplencia: false });
        Alert.error('Não foi possivel validar situação do cadastro', err);
      });
  };

  //#endregion identificacao
  render() {
    const {
      listRequerentes,
      user,
      isServidor,
      permiteItbiRural = false
    } = this.props;
    const {
      dadosImoveis,
      dadosIdentificacao,
      loadingRascunhoEditar,
      pessoaQuickView,
      step1,
      step2,
      step3,
      vinculos,
      parametros,
      loadingRequerente,
      loadingInadimplencia
    } = this.state;
    return (
      <React.Fragment>
        <Loading
          loading={
            step1.submitting ||
            loadingRascunhoEditar ||
            step3.submitting ||
            loadingRequerente ||
            loadingInadimplencia
          }
        />
        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />
        <Wizard
          beforeChange={this.onBeforeChange}
          allowInvalidChange={false}
          onFinish={this.finalizar}
          showExitButton={false}
        >
          <Wizard.Step
            stepId="1"
            label="Imóvel"
            icon="far fa-building"
            valid={step1.valid}
            errorMessage={step1.errorMessage}
            showExitButton={false}
            showFinishButton={false}
            submittingNextButton={step1.submitting}
          >
            <Imovel
              dadosImoveis={dadosImoveis}
              handleChangeFieldCurrency={this.handleChangeFieldCurrency}
              handleChangeSelectTipoItbi={this.handleChangeSelectTipoItbi}
              handleChangeSelectRequerente={this.handleChangeSelectRequerente}
              handleChangeCadastroImobiliario={
                this.handleChangeCadastroImobiliario
              }
              handleClearCadastroImobiliario={
                this.handleClearCadastroImobiliario
              }
              handleKeyDownCadastroImobiliario={
                this.handleKeyDownCadastroImobiliario
              }
              handleBlurCadastroImobiliario={this.handleBlurCadastroImobiliario}
              handleChangeFieldDocument={this.handleChangeFieldDocument}
              handleChangeField={this.handleChangeField}
              errorMessages={errorMessages}
              handleChangeUploadFile={this.handleChangeUploadFile}
              isServidor={isServidor}
              onChangeInputValue={this.onChangeInputValue}
              onChangeInputCpfCnpj={this.onChangeInputCpfCnpj}
              listRequerentes={listRequerentes}
              permiteItbiRural={permiteItbiRural}
              permiteBuscaPorInscricaoCadastral={
                parametros?.buscaImobiliarioPorInscricao
              }
              handleChangeCadastroRural={this.handleChangeCadastroRural}
              handleSearchCadastroRural={this.searchCadastroRural}
            />
          </Wizard.Step>
          <Wizard.Step
            stepId="2"
            label="Identificação"
            icon="far fa-users"
            valid={step2.valid}
            errorMessage={step2.errorMessage}
            showExitButton={false}
            showFinishButton={false}
          >
            <Identificacao
              dadosIdentificacao={dadosIdentificacao}
              adicionarComprador={this.adicionarComprador}
              editarComprador={this.editarComprador}
              deletarComprador={this.deletarComprador}
              adicionarVendedor={this.adicionarVendedor}
              editarVendedor={this.editarVendedor}
              deletarVendedor={this.deletarVendedor}
              adicionarAnuente={this.adicionarAnuente}
              editarAnuente={this.editarAnuente}
              deletarAnuente={this.deletarAnuente}
              validaRgComprador={parametros?.validaRgComprador ?? true}
              user={user}
              requerente={dadosImoveis.requerente}
              anuencia={dadosImoveis.tipoItbi && dadosImoveis.tipoItbi.anuencia}
              vinculos={vinculos}
              onViewPessoa={this.onViewPessoa}
              isServidor={isServidor}
            />
          </Wizard.Step>
          <Wizard.Step
            stepId="3"
            label="Resumo"
            icon="far fa-file-alt"
            valid={step3.valid}
            errorMessage={step3.errorMessage}
            showExitButton={false}
            submittingFinishButton={step3.submitting}
          >
            <Resumo
              compradores={dadosIdentificacao.compradores}
              vendedores={dadosIdentificacao.vendedores}
              anuentes={dadosIdentificacao.anuentes}
              dadosImovel={dadosImoveis}
              onViewPessoa={this.onViewPessoa}
              isServidor={isServidor}
            />
          </Wizard.Step>
        </Wizard>
      </React.Fragment>
    );
  }
}

const WithServiceComponent = withService({
  imobiliarioService: ImobiliarioService,
  parametroService: ParametroService,
  declaracaoService: DeclaracaoService,
  uploadService: UploadService,
  proprietarioService: ProprietarioService,
  userService: UserService,
  cadastroRuralService: CadastroRuralService,
  cadastroGeralService: CadastroGeralService
})(DeclaracaoItbiForm);

export { WithServiceComponent as default, DeclaracaoItbiForm };
