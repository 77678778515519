import React from 'react';

import AccessibilityContent from './AccessibilityContent';
import AjustesContent from './AjustesContent';
import EloNotificationContent from './EloNotificationContent';
import ModulosContent from './ModulosContent';
import NotificationContent from './NotificationContent';
import TaskListContent from './TaskListContent';

interface StaticComponents {
  AccessibilityContent: typeof AccessibilityContent;
  AjustesContent: typeof AjustesContent;
  NotificationContent: typeof NotificationContent;
  EloNotificationContent: typeof EloNotificationContent;
  ModulosContent: typeof ModulosContent;
  TaskListContent: typeof TaskListContent;
}

export type FeaturesContentProps = {
  children: React.ReactNode;
};

const FeaturesContent: React.FC<FeaturesContentProps> & StaticComponents = ({
  children
}) => <div className="features-group new">{children}</div>;

FeaturesContent.AccessibilityContent = AccessibilityContent;
FeaturesContent.AjustesContent = AjustesContent;
FeaturesContent.NotificationContent = NotificationContent;
FeaturesContent.EloNotificationContent = EloNotificationContent;
FeaturesContent.ModulosContent = ModulosContent;
FeaturesContent.TaskListContent = TaskListContent;

export default FeaturesContent;
