import React from 'react';
import NotificationSystem, {
  Notification,
  System
} from 'react-notification-system';
import { connect } from 'react-redux';

import * as NotificationActions from './state/actions';
import NotificationReducer from './state/reducer';

interface ExtendedNotification extends Notification {
  clear: boolean;
  remove: string;
}

type Props = {
  notifications: ExtendedNotification[];
  handleNotifications: (action: any) => any;
};

class NotificationContainer extends React.Component<Props> {
  notificationRef: React.RefObject<System> = React.createRef();

  componentDidUpdate(prevProps: Props) {
    const { notifications, handleNotifications } = this.props;
    if (
      notifications &&
      notifications.length > 0 &&
      prevProps.notifications !== notifications
    ) {
      const notificationIds = notifications.map(
        notification => notification.uid
      );
      handleNotifications(notificationIds);
      notifications.forEach((notification: ExtendedNotification) => {
        if (notification.clear) {
          this.notificationRef.current!.clearNotifications();
        } else if (notification.remove) {
          this.notificationRef.current!.removeNotification(notification.remove);
        } else {
          this.notificationRef.current!.addNotification(notification);
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <div
          className="no-print"
          style={{ zIndex: 999991, position: 'relative' }}
        >
          <NotificationSystem ref={this.notificationRef} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  notifications: state.notification
});

export default connect(mapStateToProps, {
  handleNotifications: NotificationActions.handleNotifications
})(NotificationContainer);

export { NotificationReducer, NotificationActions };
