import classnames from 'classnames';
import React, { useContext } from 'react';

import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

interface TaskListButtonProps {}

const TaskListButton: React.FC<TaskListButtonProps> = () => {
  const headerContext = useContext(HeaderContext);

  const className = classnames({
    active: headerContext.activeTaskList
  });

  return (
    <li
      className={className}
      title="Tarefas"
      onClick={headerContext.toggleTaskList}
    >
      <Icon icon="tasks" className={'icon'} />
      {headerContext.taskCount > 0 && (
        <span className="bubble-notification">{headerContext.taskCount}</span>
      )}
      <small className="hidden-xs">Tarefas</small>
    </li>
  );
};

export default TaskListButton;
