import { TaskListModal } from '@elotech/react-components';
import React, { useContext } from 'react';

import KeycloakService from '../service/KeycloakService';
import Header from './Header';

type TaskListContentProps = {};

const TaskListContent: React.FC<TaskListContentProps> = () => {
  const { activeTaskList, setTaskCount } = useContext(Header.HeaderContext);

  const token = KeycloakService.getToken();

  return token ? (
    <div style={{ zIndex: 99999, position: 'relative' }}>
      <TaskListModal
        host="."
        token={token}
        active={activeTaskList}
        countItemsPending={e => setTaskCount(e)}
      ></TaskListModal>
    </div>
  ) : (
    <></>
  );
};

export default TaskListContent;
