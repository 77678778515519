import axios, { AxiosPromise } from 'axios';

import { Parametro, PermissaoParcelamentoDTO } from '../type';

export const loadTiposItbi = (isCidadao: boolean): AxiosPromise<any> =>
  axios.get(`/api/tipos-itbi/visiveis?isCidadao=${!isCidadao}`);

export const loadDocumentoByTipoItbi = (tipoItbi: string): AxiosPromise<any> =>
  axios.get(`/api/parametros/tipos-itbi/${tipoItbi}/documentos`);

export const loadTipoCamposDocumentoItbi = (): AxiosPromise<any> =>
  axios.get('/api/parametros/tipos-campo-itbi');

export const loadAllParametros = (): AxiosPromise<Parametro> =>
  axios.get('/api/parametros');

export const save = (parametro: Parametro) =>
  parametro.id === ''
    ? axios.post('/api/parametros', parametro)
    : axios.put(`/api/parametros/${parametro.id}`, parametro);

export const loadPermissaoParcelamento = (): AxiosPromise<PermissaoParcelamentoDTO> =>
  axios.get('/api/parametros/permissao-parcelamento');
